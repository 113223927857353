<template>
  <div class="modal modal_custom" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="columns is-multiline is-centered">
          <div class="column is-12">
            <p class="modal-card-title has-text-centered">Estatus de la transacción</p>
          </div>
          <div class="column is-12">
            <div class="media is-align-items-center">
              <div class="media-left">
                <p class="font_14 has_gray has-text-weight-semibold">
                  Estatus:
                </p>
              </div>
              <div class="media-content">
                <div class="field">
                  <div class="control">
                    <div class="select is-fullwidth">
                      <select v-model="transactionStatus" name="transactionStatus" v-validate="'required'" data-vv-as="del estatus de la transacción">
                        <option selected disabled :value="null">Estatus</option>
                        <option
                          v-for="item in transactionStatusList"
                          :key="item.id"
                          :value="item.id"
                          :disabled="item.id === PENDING"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="insufficientBalance || ((balanceUser < productPrice * requestedAmount) && transactionStatus === APPROVED)">
            <div class="column is-12">
              <div class="notification is-warning">
                No es posible realizar esta acción, revisa el monto disponible para hacerlo.
              </div>
            </div>
          </template>
          <template v-if="(balanceUser < dataDetail.product_price * dataDetail.product_total_pieces) && transactionStatus === APPROVED">
            <div class="column is-12">
              <div class="field">
                <p class="font_14 has_gray has-text-weight-semibold mb-2">
                  Cantidad solicitada
                </p>
                <div class="control">
                  <input
                    class="input px-3 py-3"
                    type="text"
                    placeholder="Cantidad"
                    v-model="requestedAmount"
                    name="requestedAmount"
                    v-validate="`required|max_value:${dataDetail.product_total_pieces}|min_value:1|numeric`"
                    data-vv-as="de la cantidad"
                    @input="insufficientBalance = false"
                  >
                  <span v-show="vverrors.has('requestedAmount')" class="help is-danger">{{ vverrors.first('requestedAmount') }}</span>
                </div>
              </div>
            </div>
          </template>
        </div>
      </section>
      <footer class="modal-card-foot is-justify-content-space-around">
        <button
          class="button is_outlined"
          :disabled="loading"
          @click="$emit('close-modal')"
        >
          Cancelar
        </button>
        <button
          class="button btn_violet"
          :disabled="loading || transactionStatus === PENDING"
          :class="{ 'is-loading' : loading }"
          @click="setDataAction"
        >
          Guardar
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalTransactionStatus',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    dataDetail: {
      type: Object,
      default: () => {}
    },
    balanceUser: {
      type: [Number, String],
      default: 0
    }
  },
  data () {
    return {
      transactionStatus: null,
      PENDING: 1,
      APPROVED: 2,
      transactionStatusList: [
        {
          id: 1,
          name: 'Pendiente'
        },
        {
          id: 2,
          name: 'Aceptado'
        },
        {
          id: 3,
          name: 'Rechazado'
        }
      ],
      productPrice: 0,
      productTotalPieces: 0,
      requestedAmount: 0,
      insufficientBalance: false
    }
  },
  methods: {
    spredData () {
      this.transactionStatus = this.dataDetail.status
      this.productPrice = this.dataDetail.product_price
      this.productTotalPieces = this.dataDetail.product_total_pieces
      this.requestedAmount = this.dataDetail.requested_amount
    },
    async setDataAction () {
      if ((this.balanceUser < this.productPrice * this.requestedAmount) && this.transactionStatus === this.APPROVED) {
        this.insufficientBalance = true
      } else {
        if (await this.$validator.validateAll()) {
          const dataSetUpdate = {
            transactionId: this.dataDetail.id,
            transactionStatus: this.transactionStatus,
            requestedAmount: this.requestedAmount,
            sellerId: this.dataDetail.provider_id,
            buyerId: this.dataDetail.user_id,
            productId: this.dataDetail.product_id
          }

          this.$emit('update-status-action', dataSetUpdate)
        }
      }
    }
  },
  beforeMount () {
    this.spredData()
  }
}
</script>

<style lang="scss" scoped>
  .modal_custom {
    .button {
      width: 140px;
    }
  }
  .select select {
    border-radius: 6px;
  }
  .select:not(.is-multiple):not(.is-loading)::after {
    margin-top: -0.5em;
    border-color: #5dca96;
  }

  .font_10 {
    color: #747474;
  }
</style>
